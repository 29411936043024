// Import SVG files:



const Map = () => {
    return (
        <div className="container-fluid bg-white">
        </div>
    )
};

export default Map;